import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const BASE_URL = "https://infiboutique.in/api/api/";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { items } = location.state || { items: [] };

  const [formData, setFormData] = useState({
    firstName: "",
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    pinCode: "",
    phone: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        const response = await axios.get(`${BASE_URL}user-address/${userId}/`);
        const address = response.data;

        if (address) {
          setFormData({
            firstName: address.name || "",
            country: address.country || "",
            streetAddress: address.street || "",
            city: address.city || "",
            state: address.state || "",
            pinCode: address.postal_code || "",
            phone: address.phone || "",
          });
        }
      } catch (error) {
        console.error("Error fetching user address:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserAddress();
  }, [userId]);

  const calculateDeliveryCharge = (state) => {
    return state.toLowerCase() === "tamil nadu" ||
      state.toLowerCase() === "tn" ||
      state.toLowerCase() === "tamilnadu"
      ? 80
      : 120;
  };

  const deliveryCharge = calculateDeliveryCharge(formData.state);

  const calculateSubtotal = (items) => {
    return items.reduce((acc, product) => acc + product.item_price, 0);
  };

  const subtotal = calculateSubtotal(items);

  const total = subtotal + deliveryCharge;

  const cart_item_ids = items.map((item) => item.cart_item_id);

  const handlePayment = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const orderData = {
      user_id: userId, // Ensure userId is set correctly
      cart_items: items.map((item) => ({
        cart_item_id: item.cart_item_id, // Ensure cart_item_id is correctly referenced
        item_price: item.item_price, // This should match the API requirements
      })),
      name: formData.firstName,
      street: formData.streetAddress,
      city: formData.city,
      state: formData.state,
      postal_code: formData.pinCode,
      country: formData.country,
      mobile: formData.phone,
      total_amount: total, // Total amount in paise
    };

    try {
      const response = await axios.post(`${BASE_URL}place-order/`, orderData);

      const { razorpay_order_id, amount, currency } = response.data;

      // Check if razorpay_order_id is valid
      if (!razorpay_order_id) {
        Swal.fire({
          icon: "error",
          title: "Order Error",
          text: "Unable to create the order. Please try again.",
        });
        return;
      }

      // Set up Razorpay options
      const options = {
        key: "rzp_live_nuZKcLrhuStOWs", // Replace with your Razorpay key
        amount: amount, // Amount in paise
        currency: currency,
        name: "Infiboutique",
        description: "Order Payment",
        order_id: razorpay_order_id,
        handler: async (response) => {
          const paymentResponse = {
            razorpay_order_id: razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };
          await confirmPayment(razorpay_order_id, paymentResponse);
        },
        prefill: {
          name: formData.firstName,
          email: "", // Add user's email if available
          contact: formData.phone,
        },
        notes: {
          address: `${formData.streetAddress}, ${formData.city}, ${formData.state}, ${formData.pinCode}, ${formData.country}`,
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error(
        "Error creating order:",
        error.response ? error.response.data : error.message
      );
      const errorMessage = error.response
        ? error.response.data.message ||
          "There was an issue creating your order."
        : "Network error. Please try again.";

      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      });
    }
  };

  const confirmPayment = async (orderId, paymentResponse) => {
    const paymentData = {
      razorpay_payment_id: paymentResponse.razorpay_payment_id,
      razorpay_order_id: orderId,
      razorpay_signature: paymentResponse.razorpay_signature,
      user_id: localStorage.getItem("userId"),
      order_amount: total, // Ensure this is dynamically set based on your actual total
      name: formData.firstName,
      street: formData.streetAddress,
      city: formData.city,
      state: formData.state,
      postal_code: formData.pinCode,
      country: formData.country,
      mobile: formData.phone,
      cart_item_ids: cart_item_ids,
    };

    try {
      await axios.post(`${BASE_URL}place-order-after-payment/`, paymentData);

      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: "Your order has been successfully placed!",
      });
    } catch (error) {
      console.error("Error confirming payment:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue confirming your payment. Please try again.",
      });
    }
  };

  return (
    <div>
      <h2 className="text-center my-5">Payment</h2>
      <div className="checkout_section" id="accordion">
        <div className="container">
          <div className="checkout_form">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                {isLoading ? (
                  <p>Loading address...</p>
                ) : (
                  <form action="#">
                    <h3>Billing Details</h3>
                    {/* Billing fields */}
                    <div className="checkout_form_input">
                      <label>Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.firstName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>Country / Region *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.country}
                        onChange={(e) =>
                          setFormData({ ...formData, country: e.target.value })
                        }
                        placeholder="Enter your country"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>Street address *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.streetAddress}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            streetAddress: e.target.value,
                          })
                        }
                        placeholder="Enter your street address"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>Town / City *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.city}
                        onChange={(e) =>
                          setFormData({ ...formData, city: e.target.value })
                        }
                        placeholder="Enter your city"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>State *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.state}
                        onChange={(e) =>
                          setFormData({ ...formData, state: e.target.value })
                        }
                        placeholder="Enter your state"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>PIN Code *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.pinCode}
                        onChange={(e) =>
                          setFormData({ ...formData, pinCode: e.target.value })
                        }
                        placeholder="Enter your postal code"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>Phone *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.phone}
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </form>
                )}
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="order_table_right">
                  <form action="#">
                    <h3>Your order</h3>
                    <div className="order_table table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product</th>
                            <th>Size</th>
                            <th>Length</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((product) => (
                            <tr key={product.id}>
                              <td>
                                <div className="paymet_img">
                                  <img
                                    src={product.images[0]}
                                    alt={product.product_name}
                                  />
                                </div>
                              </td>
                              <td>{product.product_name}</td>
                              <td>{product.selected_options.size}</td>
                              <td>{product.selected_options.length_inch}</td>
                              <td>{product.item_price} INR</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="order_total">
                      <h3>Subtotal: {subtotal} INR</h3>
                      <h3>Delivery Charge: {deliveryCharge} INR</h3>
                      <h3>Total: {total} INR</h3>
                    </div>

                    <div className="place_order_btn">
                      <Link
                        className="btn btn-success"
                        to="#"
                        onClick={handlePayment}
                        disabled={isLoading}
                      >
                        place order
                      </Link>
                    </div>
                    <Link to="#" onClick={() => navigate(-1)}>
                      <button className="btn btn-primary">Go Back</button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
