import React, { useState, useEffect } from "react";
import Minicart from "../Pages/Minicart";
import { Link } from "react-router-dom";
import { fetchcategorylist, fetchcart } from "../Pages/Apiservice";
import SearchModal from "../Pages/SearchModal";
import Loading from "../Pages/Loading";

function Header() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for Product dropdown
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [pageloading, setPageloading] = useState(true);
  useEffect(() => {
    const getCategories = async () => {
      try {
        const data = await fetchcategorylist();
        setCategories(data);
        setLoading(false);
      } catch (error) {
        setError("Failed to load categories.");
        setLoading(false);
      } finally {
        setPageloading(false); // Set loading to false after data is fetched
      }
    };
    getCategories();
  }, []);
  useEffect(() => {
    const getCartItems = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (userId) {
          const data = await fetchcart(userId); // Fetch cart based on user_id
          console.log("Cart Data: ", data);
          setCartItems(data.items || []); // Ensure correct cart structure
        } else {
          console.error("No userid found in localStorage");
        }
      } catch (error) {
        console.error("Failed to fetch cart items", error);
      }
    };
    getCartItems();
  }, []);

  const toggleCart = () => {
    setIsCartOpen((prevState) => !prevState); // Toggle the visibility state
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  const [isScrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const toggleSearchModal = () => {
    setIsSearchModalOpen((prevState) => !prevState);
  };
  if (pageloading) {
    return <Loading />;
  }
  return (
    <header className={`header_section ${isScrolled ? "header_scrolled" : ""}`}>
      <div className="header_overlay">
        <div className="header_content">
          <div className="header_top">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="header_top_inner d-flex justify-content-between align-items-center">
                    <div className="header_contact_info">
                      <ul className="d-flex">
                        <li className="text-white">
                          <i className="icons icon-phone"></i>
                          <Link to="tel:8807179290">8807179290 </Link>
                        </li>
                        <li className="text-white">
                          <i className="icon-envelope-letter icons"></i>
                          <Link to="mailto:infi.boutique1205@gmail.com">
                            infi.boutique1205@gmail.com
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="header_top_sidebar d-flex align-items-center">
                      <div className="header_social d-flex">
                        <span>Follow us</span>
                        <ul className="d-flex">
                          <li>
                            <Link to="#">
                              <i className="icon-social-twitter icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-facebook icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-instagram icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-youtube icons"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="icon-social-pinterest icons"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main_header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="header_container d-flex justify-content-between align-items-center">
                    <div className="canvas_open" onClick={toggleNav}>
                      <Link to="javascript:void(0)">
                        <i className="ion-navicon"></i>
                      </Link>
                    </div>
                    <div className="header_logo">
                      <Link className="sticky_none" to="/">
                        <h3
                          style={{
                            fontFamily: '"Caveat", cursive',
                            fontOpticalSizing: "auto",
                            paddingLeft: "30px",
                            color: "black",
                          }}
                        >
                          Infiboutique
                        </h3>
                        {/* <img
                          src="./assets/img/logo/logo.jpg"
                          alt=""
                          style={{ height: "50px" }}
                        /> */}
                      </Link>
                    </div>

                    <div className="main_menu d-none d-lg-block">
                      <nav>
                        <ul className="d-flex align-items-center justify-content-center">
                          <li>
                            <Link className="active" to="/">
                              Home
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="#" className="nav-link ">
                              Product
                            </Link>
                            <ul className="dropdown_Product pl-0 px-2">
                              {categories.map((category) => (
                                <li
                                  key={category.id}
                                  style={{ width: "150px" }}
                                >
                                  <Link to={`/product/${category.id}`}>
                                    {category.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </li>

                          <li>
                            <Link to="/Cart">Cart </Link>
                          </li>
                          <li>
                            <Link to="/Trackorder"> Track Your Order </Link>
                          </li>
                          <li>
                            <Link to="/Contact">Contact </Link>
                          </li>
                          <li>
                            <Link to="/AuthForm">signup/login </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="header_account">
                      <ul className="d-flex">
                        <li
                          className="header_search"
                          onClick={toggleSearchModal}
                        >
                          <Link to="#" style={{ color: "black" }}>
                            <i className="icon-magnifier icons"></i>
                          </Link>
                        </li>

                        <li>
                          <Link to="/Favourite" style={{ color: "black" }}>
                            <i className="icon-heart icons"></i>
                          </Link>
                          {/* <span className="item_count">2</span> */}
                        </li>
                        <li
                          className="shopping_cart"
                          onClick={toggleCart}
                          style={{ color: "black" }}
                        >
                          <Link to="#" style={{ color: "black" }}>
                            <i className="icon-basket-loaded icons"></i>
                          </Link>
                          {/* <span className="item_count">{cartItems.length}</span> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`mobile_nav ${isNavOpen ? "open" : ""}`}>
        <i
          className="ion-android-close"
          onClick={closeNav}
          style={{ color: "black", fontSize: "30px", marginLeft: "30px" }}
        ></i>
        <ul>
          <li>
            <Link to="/" onClick={closeNav}>
              Home{" "}
            </Link>
          </li>
          <li className="productheaderalign-mobile">
            <Link to="#" onClick={toggleDropdown}>
              Product{" "}
              <i className="arrow-down" style={{ marginLeft: "5px" }}></i>
            </Link>
            {isDropdownOpen && (
              <ul className="dropdown_Product">
                {categories.map((category) => (
                  <li key={category.id}>
                    <Link to={`/product/${category.id}`} onClick={closeNav}>
                      {" "}
                      {category.name}{" "}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>

          <li>
            <Link to="/Cart" onClick={closeNav}>
              Cart{" "}
            </Link>
          </li>
          <li>
            <Link to="/Trackorder" onClick={closeNav}>
              Track Your Order
            </Link>
          </li>
          <li>
            <Link to="/Contact" onClick={closeNav}>
              Contact{" "}
            </Link>
          </li>
          <li>
            <Link to="/AuthForm" onClick={closeNav}>
              signup/login{" "}
            </Link>
          </li>
        </ul>
      </div>
      <Minicart isOpen={isCartOpen} onClose={closeCart} />
      <SearchModal isOpen={isSearchModalOpen} onClose={toggleSearchModal} />
    </header>
  );
}

export default Header;
